<template>
  <div
    class="contact-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="topheader">
      <div class="topheaderfx">
        <div class="backcol">
          <button
            class="backbt"
            @click="
              () => {
                this.$router.push('/my');
              }
            "
          >
            <i class="iconfont icon-left-01"></i
            ><span>{{ PAGELANG.工作台 }}</span>
          </button>
        </div>
        <div class="upcol">
          <van-popover
            placement="bottom-end"
            v-model:show="showBranch"
            :actions="agentbranch"
            @select="onSelectParent"
          >
            <template #reference>
              <span><i class="iconfont icon-xiangshang"></i> </span>
            </template>
          </van-popover>
        </div>
      </div>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="SYSLANG.下拉即可刷新"
      :loosing-text="SYSLANG.释放即可刷新"
      :loading-text="SYSLANG.loading"
    >
      <van-list
        v-model:loading="isloading"
        :finished="finished"
        :finished-text="SYSLANG.finished"
        :loading-text="SYSLANG.loading"
        @load="getAgentList"
        :offset="50"
      >
        <ul class="contacts">
          <template v-for="(citem, index) in contacts" :key="index">
            <li>
              <h2>
                {{ citem.AgentName
                }}<span
                  class="status"
                  @click="
                    () => {
                      onEnterSub(citem);
                    }
                  "
                  >{{ PAGELANG.下级 }}{{ citem.SubAgentCount }}
                  <i class="iconfont icon-right-01"></i
                ></span>
              </h2>
              <div class="contact">
                <span class="contactinfo"
                  ><label class="title">{{ PAGELANG.商号 }}</label
                  ><span class="content">{{ citem.AgentCode }}</span></span
                >
                <span class="contactinfo"
                  ><label class="title">{{ PAGELANG.电话 }}</label
                  ><span class="content">{{ citem.Mobile }}</span></span
                >
                <span class="contactinfo"
                  ><label class="title">{{ PAGELANG.Email }}</label
                  ><span class="content">{{ citem.Email }}</span></span
                >
              </div>
              <div class="time">
                {{ PAGELANG.注册时间 }}
                <span>{{ formatDate(citem.AddDTime) }}</span>
              </div>
            </li>
          </template>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-overlay :show="isloading">
    <div class="loadingwraper">
      <div class="block">
        <van-loading color="#cfaf6d" size="60" />
      </div>
    </div>
  </van-overlay>
</template>
  
  <script>
//import { showToast } from "vant";
import { getCookie, formatDate } from "../util";

export default {
  data() {
    return {
      refreshing: false,
      isloading: false,
      finished: false,
      parentid: 0, //当前的parentid
      agentbranch: [], //向下浏览时，可以随时返回到上面节点
      showBranch: false,
      contacts: [],
      pagesize: 3,
      curpage: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    let agentid = getCookie("agentloginid");
    if (!agentid) {
      return;
    }

    this.parentid = agentid;

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.acontacts;

    // setInterval(() => {
    //         console.log("isloading", this.isloading, "refreshing", this.refreshing);
    //     }, 1000);
  },
  methods: {
    onEnterSub(citem) {
      this.curpage = 0;
      this.parentid = citem.ID;
      this.getAgentList();
    },
    onSelectParent(action) {
      let idx = this.agentbranch.findIndex((item) => {
        if (item == action) {
          return true;
        }
        return false;
      });

      if (idx >= 0) {
        this.curpage = 0;
        this.parentid = this.agentbranch[idx].value;
        this.agentbranch.splice(idx, this.agentbranch.length - idx);
        this.getAgentList();
      }
    },
    getAgentList() {
      this.isloading = true;
      this.finished = true;

      console.log(this.parentid, this.pagesize, this.curpage);

      this.curpage++;

      this.axios
        .get(this.actions.acontacts, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            parentid: this.parentid,
            pagesize: this.pagesize,
            curpage: this.curpage,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.isloading = false;
          this.finished = data.isfinished ? true : false;
          this.refreshing = false;

          if (data.retcode == "OK") {
            data.parentinfo.text = data.parentinfo.AgentName;
            data.parentinfo.value = data.parentinfo.ID;
            if (
              this.agentbranch.length <= 0 ||
              this.agentbranch[this.agentbranch.length - 1].value !=
                data.parentinfo.value
            ) {
              //新的上级
              this.agentbranch.push(data.parentinfo);
              this.contacts = data.contacts;
            } else {
              //翻页

              this.curpage = data.curpage;
              this.pagesize = data.pagesize;
              //this.contacts.push(...data.contacts);

              this.contacts = this.contacts.concat(data.contacts);
            }
          }
        });
    },
    formatDate(d) {
      return formatDate(d);
    },
    onRefresh() {
      //this.getAgentList();
      //this.finished = false;
      this.getAgentList();
    },
  },
};
</script>
<style>
:root {
  --van-popover-action-width: 160px;
}
</style>
  <style scoped>
.loadingwraper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingwraper .block {
  width: 60px;
  height: 60px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.backbt {
  appearance: none;
  background: none;
  border: none;
}

.topheader {
  height: 48px;
}

.topheader .topheaderfx {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  position: fixed;
  background: #fff;
  width: 100%;
  border-bottom: 1px solid #eee;
  z-index: 100;
  display: flex;
  flex-flow: row;
}

.topheader .topheaderfx .backcol {
  flex-grow: 1;
  padding-left: 10px;
}

.topheader .topheaderfx .upcol {
  flex-grow: 1;
  text-align: right;
  padding-right: 20px;
}

.contact-page h2 .status {
  font-size: 14px;
  font-weight: normal;
  color: green;
  float: right;
}

.contacts {
  padding: 10px;
}

.contacts li {
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.08);
}

.contacts li:first-child {
  margin-top: 10px;
}

.contacts li h2 {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
  padding: 0 12px;
}

.contacts li .time {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  font-size: 14px;
  color: #777;
}

.contacts li .contact {
  padding: 12px;
  margin: 8px;
  border-radius: 5px;
  background: #f7f7f7;
}

.contacts li .contactinfo {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  line-height: 23px;
  font-size: 13px;
}

.contacts li .contactinfo .title {
  display: inline-block;
  width: 30%;
}

.contacts li .contactinfo .content {
  display: inline-block;
  width: 70%;
  text-align: right;
}
</style>